import request from 'utils/request';
const api = {
  OFFER_PAGE: '/api/admin/offer/page',
  OFFER_LIST: '/api/admin/offer/list',
  OFFER_DETAILS: '/api/admin/offer/get',
  OFFER_UPDATE: '/api/admin/offer/update',
  OFFER_ADD: '/api/admin/offer/add',
  OFFER_DEL: '/api/admin/offer/delete',
  OFFER_SUBAFF_DETAILS: '/api/admin/offer/subaff/get',
  OFFER_SUBAFF_UPDATE: '/api/admin/offer/subaff/update',
};

// 分页
export function getOfferPage(query) {
  return request({
    url: api.OFFER_PAGE,
    method: 'get',
    params: query,
  });
}

// 详情
export function getOfferDetails(query) {
  return request({
    url: api.OFFER_DETAILS,
    method: 'get',
    params: query,
  });
}

// 更新
export function handleOfferUpdate(data) {
  return request({
    url: api.OFFER_UPDATE,
    method: 'post',
    data,
  });
}

// 添加
export function handleOfferAdd(data) {
  return request({
    url: api.OFFER_ADD,
    method: 'post',
    data,
  });
}

// 删除
export function fetchOfferDel(query) {
  return request({
    url: api.OFFER_DEL,
    method: 'get',
    params: query,
  });
}

// 查询Sub_aff
export function fetchOfferSubAff(query) {
  return request({
    url: api.OFFER_SUBAFF_DETAILS,
    method: 'get',
    params: query,
  });
}

// 更新Sub_aff
export function handleOfferSubAffUpdate(data) {
  return request({
    url: api.OFFER_SUBAFF_UPDATE,
    method: 'post',
    data,
  });
}
