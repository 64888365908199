import request from 'utils/request';

const api = {
  ADVERTISER_PAGE: '/api/admin/advertiser/page',
  ADVERTISER_LIST: '/api/admin/advertiser/list',
  ADVERTISER_DEL: '/api/admin/advertiser/delete',
  ADVERTISER_ADD: '/api/admin/advertiser/add',
  ADVERTISER_DETAILS: '/api/admin/advertiser/get',
  ADVERTISER_UPDATE: '/api/admin/advertiser/update',
};

// 分页
export function getAdvertiserPage(query) {
  return request({
    url: api.ADVERTISER_PAGE,
    method: 'get',
    params: query,
  });
}
// 列表
export function getAdvertiserList(query) {
  return request({
    url: api.ADVERTISER_LIST,
    method: 'get',
    params: query,
  });
}
// 详情
export function getAdvertiserDetails(query) {
  return request({
    url: api.ADVERTISER_DETAILS,
    method: 'get',
    params: query,
  });
}
// 删除
export function handleAdvertiserDel(query) {
  return request({
    url: api.ADVERTISER_DEL,
    method: 'get',
    params: query,
  });
}

// 添加
export function handleAdvertiserAdd(data) {
  return request({
    url: api.ADVERTISER_ADD,
    method: 'post',
    data,
  });
}

// 更新
export function handleAdvertiserUpdate(data) {
  return request({
    url: api.ADVERTISER_UPDATE,
    method: 'post',
    data,
  });
}
